export const FORMSETTING = {
    JUSTIMAGE: 'JUSTIMAGE',
    IMAGEANDTEXT: 'IMAGEANDTEXT',
    ALLFORM: 'ALLFORM'
}



export const editorConfiguration = {
  toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "|",
      "alignment", // Tambahkan opsi alignment ke toolbar
      "|",
      "undo",
      "redo",
  ],
};
