
import axios from 'axios';
import { getToken } from './auth';

let token = getToken()
// let dev = `http://localhost:3001/api/`
// let dev = `http://192.168.1.5:3003/api/`
let prod = `https://arti.api.artikularklinik.com/api/`

// Create an instance of Axios with custom configuration
const api = axios.create({
    baseURL: prod
    , // Replace with your API's base URL
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    },
});

export default api;