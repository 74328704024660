import { Card, CardBody } from '@material-tailwind/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {BsArrowLeft} from 'react-icons/bs'
import { createGallery } from '../../services/gallery';
import { swal } from '../../utils/alert';
import imageCompression from 'browser-image-compression';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { editorConfiguration } from 'utils/enum';

const GalleryForm = () => {
  const [galleryData, setGalleryData] = useState({
    name: '',
    description: '',
    images: [],
  });
  const [loading, setLoading] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGalleryData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = async (e, index) => {
    const images = [...galleryData.images];
    const compressedImage = await compressImage(e.target.files[0]);
  
    images[index] = compressedImage;
    setGalleryData((prevData) => ({ ...prevData, images }));
  };
  
  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1, // Ukuran maksimal file yang diizinkan (dalam megabyte)
      maxWidthOrHeight: 800, // Ukuran maksimal lebar atau tinggi gambar
      useWebWorker: true,
    };
  
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error('Error compressing image:', error);
      return file; // Jika gagal, gunakan file asli
    }
  };

  const handleAddImage = () => {
    setGalleryData((prevData) => ({ ...prevData, images: [...prevData.images, null] }));
  };

  const handleRemoveImage = (index) => {
    const images = [...galleryData.images];
    images.splice(index, 1);
    setGalleryData((prevData) => ({ ...prevData, images }));
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    // Handle form submission logic here

    setLoading(true)
    const formData = new FormData();
    galleryData.images.forEach((image, index) => {
      formData.append(`image`, image);
    });
  
    formData.append('name', galleryData.name);
    formData.append('description', galleryData.description);
  
    return await createGallery(formData)
    .then(res => {
        if(res.statusCode === 201){
            swal.success('Data berhasil dibuat')
            setTimeout(() => {
              window.location.href = '/galleries'
            }, 1000);
        }
        setLoading(false)
    }).catch(err => {
        if(err){
            swal.error(err?.response?.data?.message ?? 'Terjadi kesalahan')
        }
        setLoading(false)
    })
    
  };

  return (
    <>
     <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8 h-auto">
              <div className="container mx-auto max-w-full">
              </div>
            </div>
            <div className="px-3 md:px-8 h-auto -mt-24">
              <div className="container mx-auto max-w-full">
                <div className="grid grid-cols-1 px-4 mb-16">
                <Card>
                    <div className="flex flex-row items-center justify-between bg-teal-500 rounded-lg px-7 h-24 shadow-2xl -mt-10">
                    <h2 className="text-white text-2xl flex items-center"> {/* Using flex container */}
                    <Link to={'/galleries'} className="mr-4"> <BsArrowLeft/> </Link> {/* Added 'mr-4' for right margin */}
                    Tambah Gallery
                    </h2>

                    </div>
                    <CardBody >
                    <form className="max-w-2xl mx-auto mt-8" onSubmit={handleSubmit}>
                        <div className="w-full pr-2 mb-4">
                            
                            <input
                                id="name"
                                name="name"
                                placeholder="Judul Album"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                aria-label="Full name"
                                value={galleryData.name}
                                onChange={handleInputChange}
                                />
                            </div>
                        
                            <div className="w-full pr-2">
                            
                            <CKEditor
                              editor={ClassicEditor}
                              config={editorConfiguration}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                handleInputChange({
                                  target: {
                                    name: "description", // Sesuaikan dengan name yang Anda gunakan
                                    value: data,
                                  },
                                });
                              }}
                            />
                            
                        </div>

                        

                        <div className="mb-4">
                            <label htmlFor="images" className="block text-gray-700 font-bold mb-2">
                            Images:
                            </label>
                            {galleryData.images.map((image, index) => (
                            <div key={index} className="mb-2 flex items-center">
                                <input
                                type="file"
                                onChange={(e) => handleImageChange(e, index)}
                                className="mr-2"
                                />
                                <button type="button" onClick={() => handleRemoveImage(index)} className="bg-red-500 text-white px-2 py-1 rounded">
                                Remove
                                </button>
                                {image && (
                                <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Preview-${index}`}
                                    className="ml-2 w-16 h-16 object-cover rounded"
                                />
                                )}
                            </div>
                            ))}
                            <button type="button" onClick={handleAddImage} className="bg-blue-500 text-white px-2 py-1 rounded">
                            Add Image
                            </button>
                        </div>

                        <div>
                          {
                            loading ? (
                              <button disabled className="bg-green-500 text-white px-4 py-2 rounded">
                              Loading...
                              </button>
                            ):(
                              <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
                              Submit
                              </button>
                            )
                          }
                           
                        </div>
                        </form>
                  
                    </CardBody>
                  </Card >
                </div>
            </div>
         </div>
    </>
    
  );
};

export default GalleryForm;